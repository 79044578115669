const useReadNotificationDto = (
  options?: GraphqlMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>,
) => {
  const mutation = useGraphqlMutation(ReadNotificationSchema, options);

  return {
    ...mutation,
  };
};

export { useReadNotificationDto };
