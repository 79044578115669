const useReadNotification = () => {
  const { loading, mutate, onDone } = useReadNotificationDto();

  const readNotification = async (variables: ReadNotificationMutationVariables) => {
    await mutate(variables);
  };

  onDone(async () => {
    await useRefetchClient();
  });

  return {
    loading,
    readNotification,
  };
};

export { useReadNotification };
