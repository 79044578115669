<template>
  <header class="the-header">
    <TheHeaderCabinetTools />

    <TheHeaderCabinetUserNotification />
  </header>
</template>

<style scoped>
.the-header {
  position: sticky;
  top: 0;
  z-index: var(--z-index-header);
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: var(--header-height);
  padding-right: 8px;
  background-color: var(--white);
  box-shadow: 0 2px 12px rgb(0 0 0 / 8%);
}

@media (--desktop) {
  .the-header {
    column-gap: 40px;
    padding-right: 40px;
    padding-left: 24px;
  }
}
</style>
