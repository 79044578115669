<template>
  <div class="app typography__body2">
    <TheHeaderCabinet class="app__header" />

    <TheMenuCabinet class="app__menu ui-scrollbar-primary" />

    <main class="app__main ui-scrollbar-primary">
      <slot />
    </main>
    <TheCookie />
  </div>
</template>

<style scoped>
.app {
  background-color: var(--white-1);
}

@media (--desktop) {
  .app {
    display: grid;
    grid-template-areas:
      "header header"
      "menu main";
    grid-template-rows: min-content 1fr;
    grid-template-columns: max-content 1fr;
  }
}

@media (--desktop) {
  .app__header {
    grid-area: header;
  }
}

@media (--desktop) {
  .app__menu {
    grid-area: menu;
  }
}

@media (--desktop) {
  .app__main {
    grid-area: main;
    overflow: auto;
  }
}
</style>
