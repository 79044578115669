<template>
  <aside
    class="the-menu"
    :class="{ open: isMenuCabinetVisible }"
  >
    <div class="help">
      <div class="the-menu__block the-menu__item">
        <BaseLink
          class="ui-link-secondary"
          href="/client/about/questions"
          @click="() => closeMenu()"
        >
          Нужна помощь?
        </BaseLink>

        <BaseButton2 @click="() => closeMenu()">
          <BaseIcon2
            id="icon-cross"
            color="var(--grey-3)"
            height="16"
            width="16"
          />
        </BaseButton2>
      </div>

      <BaseDivider class="ui-divider-primary" />
    </div>

    <div class="menu__email the-menu__block the-menu__item typography__title2">
      <p class="base-text-ellipsis">{{ client.email }}</p>
    </div>

    <BaseDivider class="ui-divider-primary" />

    <nav class="the-menu__navigation">
      <template
        v-for="(item, itemIndex) in menu"
        :key="itemIndex"
      >
        <ul class="the-menu__list">
          <li
            v-for="(element, elementIndex) in item"
            :key="elementIndex"
          >
            <BaseLink
              class="the-menu__block the-menu__block-link the-menu__item"
              :class="{ active: route.path.startsWith(element.href) }"
              :href="element.href"
              @click="() => closeMenu()"
            >
              <template v-if="element.description">
                <p class="the-menu__name">
                  {{ element.name }}

                  <span class="the-menu__name-description typography__label2">{{ element.description }}</span>
                </p>
              </template>

              <template v-else>
                {{ element.name }}
              </template>

              <BaseIcon2
                id="icon-cap"
                color="var(--grey-3)"
                height="16"
                width="16"
              />
            </BaseLink>
          </li>
        </ul>

        <div
          v-if="itemIndex !== menu.length - 1"
          class="the-menu__list-divider"
        >
          <BaseDivider class="ui-divider-primary" />
        </div>
      </template>
    </nav>

    <div
      v-if="client"
      class="the-menu__footer"
    >
      <BaseDivider class="ui-divider-primary" />

      <BaseLink
        class="the-menu__block the-menu__block-link the-menu__item"
        :disabled="loading"
        @click="() => logout()"
      >
        <p class="the-menu__name">Выйти</p>

        <BaseIcon2
          id="icon-come-in"
          color="var(--grey-3)"
          height="16"
          width="16"
        />
      </BaseLink>
    </div>
  </aside>
</template>

<script lang="ts" setup>
const { loading, logout } = useAuthLogout();
const { isMenuCabinetVisible, toggleMenuCabinet } = useAppStore();
const { client } = useClientStore();
const route = useRoute();

const menu = [
  [
    {
      href: "/client/projects",
      name: "Проекты",
    },
    {
      href: "/client/profile",
      name: "Данные аккаунта",
    },
  ],
  [
    {
      description: "Информация, тарифы, контакты",
      href: "/client/about",
      name: "Больше о Donut",
    },
  ],
];

const closeMenu = () => {
  toggleMenuCabinet(false);
  pageScrollUnblock();
};
</script>

<style scoped>
.the-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-header);
  display: none;
  flex-direction: column;
  row-gap: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow-y: auto;
  background-color: var(--white);
}

@media (--desktop) {
  .the-menu {
    position: sticky;
    top: var(--header-height);
    z-index: calc(var(--z-index-header) - 1);
    display: flex;
    width: 240px;
    height: calc(100vh - var(--header-height));
  }
}

.the-menu.open {
  display: flex;
}

.the-menu__block {
  padding: 8px 16px;
}

@media (--desktop) {
  .the-menu__block {
    padding-left: 24px;
  }
}

.the-menu__block-link {
  transition-property: background-color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

@media (--hover) {
  .the-menu__block-link:hover,
  .the-menu__block-link:focus-visible {
    background-color: var(--grey-7);
  }
}

.the-menu__block-link.active {
  background-color: var(--grey-7);
}

.the-menu__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.the-menu__name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
}

.the-menu__name-description {
  color: var(--grey-3);
}

.the-menu__navigation {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.the-menu__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.the-menu__list-divider {
  padding-right: 16px;
  padding-left: 16px;
}

@media (--desktop) {
  .the-menu__list-divider {
    padding-left: 24px;
  }
}

.the-menu__footer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: auto;
}

.help {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

@media (--desktop) {
  .help {
    display: none;
  }
}
</style>
