const useAuthLogoutDto = async (payload: LogoutPayload) => {
  const response = await useFetchData<FetchData>("/auth/refresh-token", {
    body: payload,
    method: "DELETE",
  });

  return {
    ...response,
  };
};

export { useAuthLogoutDto };
