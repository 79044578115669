const useAuthLogout = () => {
  const loading = ref(false);

  const logout = async () => {
    const { getRefreshToken, removeRefreshToken } = useAuthToken();
    const { refreshToken } = getRefreshToken();

    if (refreshToken.value) {
      loading.value = true;

      const { success } = await useAuthLogoutDto({
        refresh_token: refreshToken.value,
      });

      if (success) {
        const { createAppLink } = useAppLink();
        const redirectLink = createAppLink("/", "main");

        removeRefreshToken();

        return navigateTo(
          {
            path: redirectLink,
          },
          {
            external: true,
          },
        );
      }

      loading.value = false;
    }
  };

  return {
    loading,
    logout,
  };
};

export { useAuthLogout };
